<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Nombre de especialidad*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              placeholder="Nombre"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <b-form-select
              v-model="formData.is_active"
              :options="statuses"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
    BFormSelect,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    name: 'AddEditSpecialtyForm',
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BSpinner,
      BFormSelect,
    },
    mixins: [CRUDMixin],
    props: {
      record: {
        type: Object,
        default: null,
      },
    },
    inject: ['specialtiesRepository'],

    data() {
      return {
        formData: {
          name: '',
          is_active: true,
        },
        repository: 'specialtiesRepository',
        isBusy: false,
        statuses: [
          {
            value: true,
            text: 'Activo',
          },
          {
            value: false,
            text: 'Inactivo',
          },
        ],
      }
    },

    mounted() {
      const me = this
      if (me.record) {
        me.formData = { ...me.record }
      }
    },

    validations: {
      formData: {
        name: {
          required,
        },
        is_active: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
