<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(is_active)="data">
          <div class="row">
            <b-badge
              v-if="data.item.is_active == true"
              variant="success"
            >
              Activo
            </b-badge>
            <b-badge
              v-if="data.item.is_active == false"
              variant="danger"
            >
              Inactivo
            </b-badge>
          </div>
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="patientspecialties-modal"
      ref="addpatientspecialties"
      title="Medicina"
      hide-footer
    >
      <b-card-text>
        <add-edit-specialty-form
          ref="form"
          :record="selectedRecord"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BCardText,
    BCard,
    BBadge,
  } from 'bootstrap-vue'
  import CRUDMixin from '@/mixins/CRUDMixin'
  import PermissionMixin from '@/mixins/PermissionMixin'
  import AddEditSpecialtyForm from './AddEditSpecialtyForm'
  import BaseActionsTable from '@/components/base/BaseActionsTable'

  export default {
    name: 'Specialty',
    inject: ['specialtiesRepository'],
    components: {
      BCard,
      BBadge,
      BCardText,
      BModal,
      AddEditSpecialtyForm,
      BaseActionsTable,
    },

    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'name',
            label: 'Nombre',
          },
          {
            key: 'is_active',
            label: 'Estado',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        filter: null,
        filterOn: [],
        selectedRecord: null,
        repository: 'specialtiesRepository',
        module: 'patientspecialties',
        isBusy: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      handleCreating() {
        this.selectedRecord = null
        this.$bvModal.show('patientspecialties-modal')
      },

      handleEditing(record) {
        this.selectedRecord = record
        this.$bvModal.show('patientspecialties-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
